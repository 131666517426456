import Component from './JobsApp.universal.js'
import { findComponents, hydrate, reload } from '@kaliber/build/lib/universalComponents'
import Wrapper from '/wrappers/Client'

const components = findComponents({ componentName: 'features_jobs_pageOnly_JobsApp_universal_js' })
const renderResults = components.map(componentInfo => {
  const { props } = componentInfo
  return { props, result: hydrate(<Wrapper {...props}><Component {...props} /></Wrapper>, componentInfo) }
})

if (module.hot) {
  require('@kaliber/build/lib/hot-module-replacement-client')
  module.hot.accept('./JobsApp.universal.js', () => {
    renderResults.forEach(({ props, result }) => result.update(<Wrapper {...props}><Component {...props} /></Wrapper>))
  })
}
