import { QueryStringProvider } from '@kaliber/use-query-string'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Jobs } from './Jobs'

const queryClient = new QueryClient()

export function JobsApp({ initialJobs, filters, search, layoutClassName }) {
  return (
    <QueryStringProvider {...{ search }}>
      <QueryClientProvider client={queryClient}>
        <Jobs {...{ initialJobs, filters, layoutClassName }} />
      </QueryClientProvider>
    </QueryStringProvider>
  )
}
