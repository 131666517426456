import { useTranslate, useLanguage } from '/machinery/I18n'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { useQueryString } from '@kaliber/use-query-string'
import { useReportError } from '/machinery/ReportError'
import { useQuery } from 'react-query'
import { makeSlug } from '/machinery/slug'
import { routeMap } from '/routeMap'
import { LoaderLarge } from '/features/buildingBlocks/Loader'
import { Icon } from '/features/buildingBlocks/Icon'
import { handleResponse } from '/machinery/handleResponse'
import { InfoBar } from '/features/buildingBlocks/InfoBar'
import { ContainerLg } from '/features/buildingBlocks/Container'
import getSlug from 'speakingurl'
import arrowRight from '/images/icons/arrow-right.raw.svg'
import searchIcon from '/images/icons/search.raw.svg'
import chevronDown from '/images/icons/chevron-down.raw.svg'

import styles from './Jobs.css'

export function Jobs({ initialJobs, filters, layoutClassName }) {
  const [{ department = '', term = '' }, setQueryString] = useQueryString()
  const reportError = useReportError()
  const language = useLanguage()
  const { __ } = useTranslate()

  const { data: jobs, isLoading, isError } = useQuery({
    queryKey: ['jobs', { department, term }],
    queryFn: () => getJobs({ department, term, language }),
    onError: reportError,
    initialData: initialJobs,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <ContainerLg>
        <div className={styles.jobsLayout}>
          <JobsFilter
            layoutClassName={styles.filter}
            onTermChange={handleTermChange}
            onDepartmentChange={handleDepartmentChange}
            {...{ term, filters, department }}
          />

          {isLoading && (
            <div className={styles.loader}>
              <LoaderLarge />
            </div>
          )}

          {isError && <Message text={__`jobs-error-message`} />}

          {!isError && !isLoading && (
            jobs && Boolean(jobs.length)
              ? <JobsList {...{ jobs }} />
              : <Message text={__`jobs-no-results-message`} />
          )}
        </div>
      </ContainerLg>
    </div>
  )

  function handleDepartmentChange(value) {
    setQueryString(x => ({ ...x, department: value }))

    if (value) {
      pushToDataLayer({
        event: 'filter_changed',
        metadata: {
          interaction: {
            changed: 'department',
            department: value,
          }
        }
      })
    }
  }

  function handleTermChange(value) {
    setQueryString(x => ({ ...x, term: value }))
  }
}

async function getJobs({ department, term, language }) {
  const response = await fetch(routeMap.api.v1.search(), {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'  },
    body: JSON.stringify({ department, term, language })
  })

  const result = await handleResponse(response)
  return result
}

function Message({ text }) {
  return (
    <div className={styles.componentMessage}>
      {text}
    </div>
  )
}

function JobsList({ jobs }) {
  return (
    <ul className={styles.componentList}>
      {jobs.map(({ _source: job }) => (
        <JobsListItem key={job.id} {...{ job }} />
      ))}
    </ul>
  )
}

function JobsListItem({ job }) {
  const language = useLanguage()
  const office = job.offices ?? []

  return (
    <li className={styles.componentListItem}>
      <div className={styles.wrapper}>
        <a
          className={cx(styles.link, styles.relativeToParent)}
          href={routeMap.app.jobs.detail({
            language,
            jobTitle: makeSlug({ input: job.title, language }),
            jobId: job.id
          })}
          data-x='link-to-job'
        >
          <h2 className={styles.title}>{job.title}</h2>
        </a>
        <InfoBar
          layoutClassName={styles.infoBar}
          location={office[0]?.name}
          department={job.department?.name}
          employment={job.employmentType}
        />
      </div>
      <div className={styles.linkIndicator}>
        <Icon icon={arrowRight} />
      </div>
    </li>
  )
}

function JobsFilter({ layoutClassName, onTermChange, onDepartmentChange, filters, term, department }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const departmentFilter = createFilterOptions(filters?.department?.buckets ?? [], language)

  return (
    <div className={cx(styles.componentFilter, layoutClassName)}>
      <h2 className={styles.filterTitle}>{__`jobs-filter-title`}</h2>
      <div className={styles.layout}>
        <Search layoutClassName={styles.searchWrapper} {...{ onTermChange, term }} />
        {Boolean(departmentFilter.length) && (
          <Select
            layoutClassName={styles.selectWrapper}
            {...{ departmentFilter, onDepartmentChange, department }}
          />
        )}
      </div>
    </div>
  )
}

function Search({ onTermChange, term, layoutClassName }) {
  const [input, setInput] = React.useState(null)
  const { __ } = useTranslate()

  return (
    <form className={cx(styles.componentSearch, layoutClassName)} onSubmit={handleTermChange}>
      <input
        type="search"
        className={styles.search}
        placeholder={__`jobs-search-placeholder`}
        onChange={e => setInput(e.currentTarget.value)}
        defaultValue={term}
      />

      <button className={styles.submit} type="submit">
        <Icon icon={searchIcon} layoutClassName={styles.icon} />
      </button>
    </form>
  )

  function handleTermChange(e) {
    e.preventDefault()
    onTermChange(input)

    if (input) {
      pushToDataLayer({
        event: 'filter_changed',
        metadata: {
          interaction: {
            changed: 'searchterm',
            searchTerm: input,
          }
        }
      })
    }
  }
}

function Select({ departmentFilter, layoutClassName, department, onDepartmentChange }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentSelect, layoutClassName)}>
      <select value={department} className={styles.select} onChange={handleFilterChange}>
        <option value=''>{__`jobs-filter-all-departments`}</option>
        {departmentFilter.map(({ label, value }) => (
          <option key={value} {...{ value }}>
            {label}
          </option>
        ))}
      </select>
      <Icon icon={chevronDown} layoutClassName={styles.chevron} />
    </div>
  )

  function handleFilterChange(e) {
    onDepartmentChange(e.target.value)
  }
}

function createFilterOptions(items, language) {
  return items.map(x => ({ label: x.key, value: getSlug(x.key, { lang: language }) }))
}
